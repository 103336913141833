.loginContainer {
  width: 300px;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.loginContainer form {
  width: 250px;
  height: 250px;
}

.loginCancel {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.logo {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: teal;
  font-weight: 700;
}

.loginBtn {
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  cursor: pointer;
}

.failure {
  font-size: 12px;
  color: red;
  text-align: center;
}
