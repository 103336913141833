.card {
  max-width: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

label {
  width: max-content;
  color: tomato;
  font-size: 13px;
  border-bottom: 0.5px solid tomato;
  margin: 3px 0;
}

.desc {
  font-size: 14px;
  overflow-wrap: break-word;
}

img {
  width: 100px;
  height: 100px;
}

.star {
  color: gold;
}

.username {
  font-size: 14px;
}

.date {
  font-size: 12px;
}

form {
  width: 250px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: rgb(88, 87, 87);
}

input {
  border: none;
  border-bottom: 1px solid gray;
}

input::placeholder,
textarea::placeholder {
  font-size: 12px;
  color: rgb(185, 184, 184);
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

textarea {
  border: none;
  border-bottom: 1px solid gray;
}

.submitButton {
  border: none;
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
  color: white;
  background-color: tomato;
  cursor: pointer;
}

.buttons,
.logout {
  position: absolute;
  top: 10px;
  right: 10px;
}

.button {
  letter-spacing: 2px;
  font-size: 14px;
  border: none;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

.logout {
  background-color: tomato;
}

.explain {
  background-color: gray;
  margin-right: 10px;
}

.login {
  background-color: teal;
  margin-right: 10px;
}

.register {
  background-color: slateblue;
}
